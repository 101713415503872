$dialog-position-left: auto;
$dialog-position-bottom: auto;
$dialog-position-right: auto;
$dialog-position-top: auto;
// @import "~ngx-smart-modal/ngx-smart-modal";
/*Theme colors and font*/
$primary: #00aec7;
$secondary: #0033a0;
$success: #307050;
$info: #0033a0;
$warning: #ff9900;
$danger: #e4002b;
$contrast_color: #e3e829;
$bg_success: #45a07299;
$bg_danger: #f0b3b3;
$bg_warning: #ffecb3;
$bg_info: #cfe2fc;
$sura_blue: #011e5a;

$base_color_one: #53565a;
$base_color_two: #707372;
$base_color_three: #c7c9c7;
$base_color_four: #ecf0f1;
$base_color_five: #dbe2ed;
$base_color_six: #ffffff;
