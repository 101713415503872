/* You can add global styles to this file, and also import other style files */
@import "./variables.scss";

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";

@import "~ngx-smart-modal/ngx-smart-modal";

@font-face {
  font-family: 'Sura-Regular';
  src: local('Sura'), url(./assets/fonts/SuraSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Sura-SemiBold';
  src: local('Sura'), url(./assets/fonts/SuraSans-Seminegrita.otf) format('opentype');
}

@font-face {
  font-family: 'Sura-Bold';
  src: local('Sura'), url(./assets/fonts/SuraSans-Negrita.otf) format('opentype');
}

@font-face {
  font-family: 'SuraSansLigera';
  src: local('Sura'), url(./assets/fonts/SuraSans-Ligera.otf) format('opentype');
}

* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  min-width: 350px;
  min-height: 568px;
  width: 100%;
  height: 100%;
}
body {
  font-family: 'Sura-Regular', Arial, sans-serif;
  font-size: 15px;
  color: $base_color_one;
}

button {
  border-style: none;
  font-family: 'Sura-SemiBold', Arial, sans-serif;
  font-size: 13.5px;
}
p {
  margin: 0px;
  padding: 0px;
}
/*Helpers link class*/
a {
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
}
/* buttons */
:focus {
  outline: none;
}
.btn {
  cursor: pointer;
  height: 32px;
  min-width: 142px;
  border-radius: 30px;
  border: 2px solid;
  font-weight: bold;
  padding: 0px 30px;
  text-transform: uppercase;
}
.btn-search {
  height: 32px;
  border-radius: 5px;
  border: 2px solid;
  font-weight: bold;
  padding: 0px 20px;
  margin: 0px 5px;
  text-transform: uppercase;
}
.btn-action {
  height: 32px;
  border-radius: 5px;
  border: 2px solid;
  font-weight: bold;
  padding: 0px 10px;
  margin: 0px 5px;
  text-transform: uppercase;
}
.btn-primary {
  color: $base_color_six;
  background-color: $primary;
  border-color: $primary;
  &:hover {
    background-color: #006a7a;
    border-color: #006a7a;
  }
}

.btn-secondary {
  color: $base_color_six;
  background-color: $secondary;
  border-color: $secondary;
  &:hover {
    background-color: #00277a;
    border-color: #00277a;
  }
}
.btn-success {
  color: $base_color_six;
  background-color: $success;
  border-color: $success;
  &:hover {
    background-color: $bg_success;
    border-color: $bg_success;
  }
}
.btn-danger {
  color: $base_color_six;
  background-color: $danger;
  border-color: $danger;
  &:hover {
    background-color: $bg_danger;
    border-color: $bg_danger;
  }
}
.btn-disabled {
  color: $base_color_three;
  background: $base_color_four;
  border-color: $base_color_four;
}
.btn-outline-primary {
  color: $primary;
  background-color: transparent;
  border-color: $primary;
}
.btn-outline-secondary {
  color: $secondary;
  background-color: transparent;
  border-color: $secondary;
}
.btn-outline-warning {
  color: $warning;
  background-color: transparent;
  border-color: $warning;
}
.btn-outline-danger {
  color: $danger;
  background-color: transparent;
  border-color: $danger;
}
.btn-outline-gray {
  color: $base_color_two;
  background-color: transparent;
  border-color: $base_color_two;
}

button:disabled,
button[disabled] {
  border: 1px solid #ecf0f1;
  background-color: #ecf0f1;
  color: #cccece;

  &:hover {
    border: 1px solid #ecf0f1;
    background-color: #ecf0f1;
    color: #cccece;
  }
}

/*Helpers text class*/
.title-container {
  text-align: center;
}
.title-one {
  font-size: 28px;
  color: $secondary;
  display: inline-block;
  padding-bottom: 15px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 25%;
    height: 5px;
    bottom: 0;
    left: 36%;
    background-color: #e3e829;
    border-radius: 20px;
  }
}
.text-bold {
  font-weight: bold;
}
.text-bold-medium {
  font-weight: 500;
}
.text-primary {
  color: $primary;
}
.text-secondary {
  color: $secondary;
}
.text-success {
  color: $success;
}
.text-danger {
  color: $danger;
}
.text-warning {
  color: $warning;
}
.text-info {
  color: $info;
}
.text-body {
  color: $base_color_one;
}
.text-muted {
  color: $base_color_three;
}
.text-white {
  color: $base_color_six;
}
.text-xlarge {
  font-size: 28px;
}
.text-large {
  font-size: 24px;
}
.text-medium {
  font-size: 20px;
}
.text-emphasis {
  font-size: 18px;
}
.text-small {
  font-size: 16px;
}
.text-xsmall {
  font-size: 13px;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
/*Helpers margin class*/
.m-1 {
  margin: 10px;
}
.m-2 {
  margin: 15px;
}
.m-3 {
  margin: 20px;
}
.m-4 {
  margin: 30px;
}
.m-5 {
  margin: 40px;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 15px;
}
.mt-3 {
  margin-top: 20px;
}
.mt-4 {
  margin-top: 30px;
}
.mt-5 {
  margin-top: 40px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 15px;
}
.mr-3 {
  margin-right: 20px;
}
.mr-4 {
  margin-right: 30px;
}
.mr-5 {
  margin-right: 40px;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 15px;
}
.mb-3 {
  margin-bottom: 20px;
}
.mb-4 {
  margin-bottom: 30px;
}
.mb-5 {
  margin-bottom: 40px;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 15px;
}
.ml-3 {
  margin-left: 20px;
}
.ml-4 {
  margin-left: 30px;
}
.ml-5 {
  margin-left: 40px;
}
/*Helpers padding class*/
.p-1 {
  padding: 10px;
}
.p-2 {
  padding: 15px;
}
.p-3 {
  padding: 20px;
}
.p-4 {
  padding: 30px;
}
.p-5 {
  padding: 40px;
}
.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 15px;
}
.pt-3 {
  padding-top: 20px;
}
.pt-4 {
  padding-top: 30px;
}
.pt-5 {
  padding-top: 40px;
}
.pr-1 {
  padding-right: 10px;
}
.pr-2 {
  padding-right: 15px;
}
.pr-3 {
  padding-right: 20px;
}
.pr-4 {
  padding-right: 30px;
}
.pr-5 {
  padding-right: 40px;
}
.pb-1 {
  padding-bottom: 10px;
}
.pb-2 {
  padding-bottom: 15px;
}
.pb-3 {
  padding-bottom: 20px;
}
.pb-4 {
  padding-bottom: 30px;
}
.pb-5 {
  padding-bottom: 40px;
}
.pl-1 {
  padding-left: 10px;
}
.pl-2 {
  padding-left: 15px;
}
.pl-3 {
  padding-left: 20px;
}
.pl-4 {
  padding-left: 30px;
}
.pl-5 {
  padding-left: 40px;
}

.bg-success {
  padding: 10px 25px;
  border-radius: 5px;
  background-color: $bg_success;
}
.bg-danger {
  padding: 10px 25px;
  border-radius: 5px;
  background-color: $bg_danger;
}
.bg-warning {
  padding: 10px 25px;
  border-radius: 5px;
  background-color: $bg_warning;
}
.bg-info {
  padding: 10px 25px;
  border-radius: 5px;
  background-color: $bg_info;
}

/*headers size config*/
h1 {
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}

/*inputs*/
.form-control {
  width: 100%;
  height: 40px;
  border: 2px solid $base_color_four;
  border-radius: 100px;
  opacity: 1;
  padding: 0px 20px;
  transition: border 0.3s;
  margin-bottom: 12px;
  margin-top: 10px;
  &::placeholder {
    color: $base_color_three;
  }
  &.border-danger {
    border-color: $danger;
  }
  &.border-success {
    border-color: $success;
  }
  &.border-warning {
    border-color: $warning;
  }
  &:focus {
    border-color: $primary;
    outline: 0;
  }
}

textarea {
  width: 100%;
  border: 2px solid $base_color_four;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  transition: border 0.3s;
  margin-bottom: 12px;
  margin-top: 6px;
  &::placeholder {
    color: $base_color_three;
  }
  &.border-danger {
    border-color: $danger;
  }
  &.border-success {
    border-color: $success;
  }
  &.border-warning {
    border-color: $warning;
  }
  &:focus {
    border-color: $primary;
    outline: 0;
  }
}

/*Tables*/
.ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  background-color: $base_color_two;
  color: $base_color_six;
}
.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection
  .datatable-body-row.active
  .datatable-row-group {
  background-color: $primary;
  color: $base_color_six;

  &:hover {
    background-color: $primary;
  }
}

.datatable-row-odd {
  background-color: $base_color_four;
}

.table-top-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.basic {
    justify-content: space-between;
  }

  .search-container {
    margin-right: 10px;
  }

  select {
    border: 1px solid $base_color_three;
    height: 30px;
    width: 48px;
  }

  span {
    padding-left: 10px;
  }
}

.pages {
  border-top: 3px solid $primary;
}

.pages.active ~ .pages {
  border-top: 3px solid $base_color_three;
}

.datatable-footer-inner {
  height: auto !important;
  flex-direction: column;

  .page-count {
    width: 100%;
  }
}

.datatable-checkbox input[type="checkbox"]:checked:before {
  border-color: $primary;
}

.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  color: inherit;
}

.datatable-body-row.datatable-row-even.active,
.datatable-body-row.datatable-row-odd.active {
  background-color: $primary;
  color: $base_color_six;

  &:hover {
    background-color: $primary;
    color: $base_color_six;
  }
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  background-color: $base_color_three;
}

/* RADIOS & CHECKBOXES STYLES */
/* SOURCE: https://gist.github.com/dciccale/1367918 */
/* No more blue blur border */
input {
  outline: none;
}

/* base styles */
input[type="radio"],
input[type="checkbox"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin: 0 0.4em 0.4em 0;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #aaaaaa;
  -webkit-appearance: none;
}

/* border radius for radio*/
input[type="radio"] {
  border-radius: 100%;
}

/* border radius for checkbox */
input[type="checkbox"] {
  border-radius: 2px;
}

/* hover state */
input[type="radio"]:not(:disabled):hover,
input[type="checkbox"]:not(:disabled):hover {
  border: 1px solid $primary;
}

/* active state */
input[type="radio"]:active:not(:disabled),
input[type="checkbox"]:active:not(:disabled) {
  border: 1px solid $primary;
}

/* input checked border color */
input[type="radio"]:checked,
input[type="checkbox"]:checked {
  border: 1px solid $primary;
}

input[type="checkbox"]:checked:not(:disabled) {
  background: $primary;
}

input.secondary[type="checkbox"]:checked:not(:disabled) {
  background: $secondary;
}

input.secondary[type="radio"]:checked,
input.secondary[type="checkbox"]:checked {
  border: 1px solid $secondary;
}

input.secondary[type="radio"]:not(:disabled):hover,
input.secondary[type="checkbox"]:not(:disabled):hover {
  border: 1px solid $secondary;
}

/* radio checked */
input[type="radio"]:checked:before {
  display: block;
  height: 14px;
  width: 14px;
  position: relative;
  left: 2px;
  top: 2px;
  background: $primary;
  border-radius: 100%;
  content: "";
}

/* checkbox checked */
input[type="checkbox"]:checked:before {
  font-weight: bold;
  color: white;
  content: "\2713";
  margin-left: 2px;
  font-size: 14px;
}

/* disabled input */
input:disabled {
  background: rgba(0, 0, 0, 0.3);
  &:checked {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

/* disabled select */
select:disabled {
  background: rgba(0, 0, 0, 0.3);
  &:checked {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

input[type="radio"]:disabled:checked:before {
  display: block;
  height: 14px;
  width: 14px;
  position: relative;
  left: 2px;
  top: 2px;
  background: rgba(255, 255, 255, 1);
  border-radius: 100%;
  content: "";
}

/* SWITCH STYLES */
/* SOURCE: https://proto.io/freebies/onoff/ */
.onoffswitch {
  position: relative;
  width: 56px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 20px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 22px;
  padding: 0;
  line-height: 22px;
  font-size: 14px;
  color: black;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "ON";
  padding-left: 9px;
  background-color: $primary;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 6px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 16px;
  margin: 5px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  border: 2px solid #ffffff;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/*
  A copy of our modal styles you can use.. its implementers choice what styles they want based on their lib of choice. If you make bootstrap modals, use their styles. Simples.
*/
.modal {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 0 16px;
  position: fixed;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 10; /* Adjust according to your needs */
}

.modal.fade-anim {
  transition: opacity 400ms ease-in-out;
  will-change: opacity;
  opacity: 0;
}

.modal.fade-anim.in {
  opacity: 1;
}

.modal-open {
  overflow: hidden;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 4px;
  margin: 16px auto;
  max-width: 580px;
  position: relative;
  transition: opacity 400ms ease-in-out;
  width: 100%;
  will-change: opacity;
  display: inline-block;
  text-align: left;
}

.modal-content-size-m {
  max-width: 992px;
}

.modal-content-size-l {
  max-width: 1200px;
}

.modal-footer,
.modal-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  padding: 0 16px;
}

.modal-header {
  border-bottom: 1px solid #cecece;
}

.modal-body {
  padding: 16px;
}

.modal-footer {
  border-top: 1px solid #cecece;
}

.full-width {
  width: 100%;
}

.nsm-content {
  border-radius: 10px;
  padding: 30px 70px;
}

/* Tooltips */
abbr[data-title] {
  position: relative;
  text-decoration: underline dotted;
}
abbr[data-title]:hover::after,
abbr[data-title]:focus::after {
  content: attr(data-title);
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  width: auto;
  white-space: nowrap;
  background: #fff;
  color: $secondary;
  border-radius: 2px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
  font-size: 14px;
  padding: 3px 5px;
}

// Spinner
#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: $primary;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.day-background-upper.is-selected,
.month-unit.is-selected,
.year-unit.is-selected {
  background: $primary !important;
}

.day-background-upper:hover,
.month-unit:hover,
.year-unit:hover {
  background: #00aec777 !important;
}

.search-input {
  position: relative;

  i {
    position: absolute;
    top: 36px;
    right: 20px;
    color: $base_color_five;
  }
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.w-lg-100 {
  width: 100%;
}

.w-lg-90 {
  width: 90%;
}

.w-lg-80 {
  width: 80%;
}

.w-lg-70 {
  width: 70%;
}

.w-lg-60 {
  width: 60%;
}

.w-lg-50 {
  width: 50%;
}

.w-lg-40 {
  width: 40%;
}

.w-lg-30 {
  width: 30%;
}

.w-lg-20 {
  width: 20%;
}

.w-lg-10 {
  width: 10%;
}

.search-sm {
  display: none;
}

.d-none {
  display: none;
}

.d-none-sm {
}

.d-block {
  display: block;
}

.d-block-sm {
}

.d-flex {
  display: block;
}

.d-flex-sm {
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-container-nowrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.link-aux {
  border-bottom: 1px solid;
}

@media (max-width: 768px) {
  .w-lg-100 {
  }

  .w-lg-90 {
  }

  .w-lg-80 {
  }

  .w-lg-70 {
  }

  .w-lg-60 {
  }

  .w-lg-50 {
  }

  .w-lg-40 {
  }

  .w-lg-30 {
  }

  .w-lg-20 {
  }

  .w-lg-10 {
  }

  .w-sm-100 {
    width: 100%;
  }

  .w-sm-90 {
    width: 90%;
  }

  .w-sm-80 {
    width: 80%;
  }

  .w-sm-70 {
    width: 70%;
  }

  .w-sm-60 {
    width: 60%;
  }

  .w-sm-50 {
    width: 50%;
  }

  .w-sm-40 {
    width: 40%;
  }

  .w-sm-30 {
    width: 30%;
  }

  .w-sm-20 {
    width: 20%;
  }

  .w-sm-10 {
    width: 10%;
  }

  .d-none {
  }

  .d-none-sm {
    display: none !important;
  }

  .d-block {
  }

  .d-block-sm {
    width: 100%;
    display: block !important;
  }

  .d-flex {
  }

  .d-flex-sm {
    width: 100%;
    display: flex !important;
  }

  .search-lg {
    display: none !important;
  }

  .search-sm {
    display: block !important;
    margin-top: 20px;

    i {
      position: absolute;
      top: 28px;
      right: 20px;
      color: #ecf0f1;
    }
  }

  .flex-container {
    flex-wrap: wrap;
  }

  .link-aux {
    background-color: $secondary;
    color: #ffffff;
    border-radius: 10px;
    padding: 8px 30px;
  }
}

::ng-deep .selectize-input {
  border-radius: 25px;
  height: 40px;
  color: black;
  border: 1px solid #ecf0f1;
  padding-left: 20px;
  font-family: Arial;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  background-image: url("/assets/img/angle-down.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: right 20px bottom 10px;
}

select {
  appearance: none;
  background-image: url("/assets/img/angle-down.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: right 20px bottom 10px;
}

.internal-container {
  padding: 30px 10%;
  min-height: calc(100% - 78px - 45px - 170px);
}

.info-bar {
  display: flex;
  justify-content: space-between;
  background-color: #ecf0f1;
  border-radius: 8px;
  flex-wrap: wrap;
  position: relative;

  .item {
    padding: 15px;
    div {
      margin: 5px 0;
    }
  }

  .expand {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    padding: 4px 14px;
  }

  &.expanded {
    border-radius: 0px 0px 8px 8px;
    // margin-top: -12px;
    .item {
      border-top: 1px solid $base_color_three;
      border-right: 1px solid $base_color_three;
      flex: 1 0 auto;

      &:nth-of-type(6) {
        border-right: none;
      }
    }
  }

  &.divided {
    flex-wrap: nowrap;

    .left {
      border-right: 1px solid $base_color_three;
    }

    .left,
    .right {
      display: flex;
      flex-wrap: wrap;
      width: 50%;

      .item {
        flex: 0 0 50%;
      }
    }
  }
}

//Styles Pill Multiselect-dropdown

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  color: #000 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid #e4e3e3 !important;

  background: #e4e3e3 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
  color: #000 !important;
}

@media (max-width: 768px) {
  .info-bar {
    border: 1px solid $base_color_four;
    .item {
      width: 50%;
      background-color: white;

      &:first-child {
        width: 100%;
        text-align: center;
        background-color: $base_color_four;
        border-radius: 8px 8px 0px 0px;
      }
    }
  }
}

// .form-group {
//   position: relative;
//   small {
//     position: absolute;
//     left: 0;
//     bottom: -4px;
//   }
//   margin-bottom: 4px;
// }

// input:read-only,
// input[read-only] {
//   border: 1px solid #ecf0f1;
//   background-color: rgba(0, 0, 0, 0.3);
//   color: rgb(84, 84, 84);
// }

/* ===== Scrollbar CSS ===== */
/* Firefox */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
  border: 1px solid #ffffff;
}
